import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useConfigStore = defineStore('config', () => {
  const leftDrawerOpen = ref(true)
  const rightDrawerOpen = ref(true)
  const primaryColor = ref('#40a9ff')
  const darkMode = ref(false)

  function toggleLeftDrawer () {
    leftDrawerOpen.value = !leftDrawerOpen.value
  }

  function toggleRightDrawer () {
    rightDrawerOpen.value = !rightDrawerOpen.value
  }

  return {
    leftDrawerOpen,
    rightDrawerOpen,
    primaryColor,
    darkMode,
    toggleLeftDrawer,
    toggleRightDrawer,
  }
}, {
  persist: {
    paths: [
      'leftDrawerOpen',
      'rightDrawerOpen',
      'primaryColor',
      'darkMode',
    ]
  }
})
